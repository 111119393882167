<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.editing }}</h4>
    <div class="admin-form-wrapper">
      <div v-if="helpItem" class="admin-form">
        <Form @submit="submit" :validation-schema="schema">

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.category }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="organizationType"
                     v-model="helpItem.organizationType">
                <option v-for="organizationType in helper.organizationTypes" :key="organizationType.value"
                        :value="organizationType.value">{{ organizationType.label }}</option>
              </Field>
            </div>
            <ErrorMessage name="organizationType" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.content_type }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="type"
                     v-model="helpItem.type">
                <option v-for="type in helper.types" :key="type.value"
                        :value="type.value">{{ type.label }}</option>
              </Field>
            </div>
            <ErrorMessage name="type" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.heading }} ({{ $lang.app.ru }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleRu" type="text"
                     v-model="helpItem.titleRu"/>
            </div>
            <ErrorMessage name="titleRu" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.heading }} ({{ $lang.app.kg }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleKg" type="text"
                     v-model="helpItem.titleKg"/>
            </div>
            <ErrorMessage name="titleKg" class="recovery-label-error" />
          </div>

          <div v-if="helpItem.type === 'URL'" class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.link }}<i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="url" type="text"
                     v-model="helpItem.url"/>
            </div>
            <ErrorMessage name="url" class="recovery-label-error" />
          </div>

          <div v-if="helpItem.type === 'DOC'" class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.attach_file }}</label>
            <Field type="file" name="file" accept="application/pdf"
                   @change="event => file = event.target.files[0]"/>
            <ErrorMessage name="file" class="recovery-label-error"/>
          </div>

          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.update }}</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      helper: {
        organizationTypes: [
          {
            value: 'SCHOOL',
            label: this.$lang.app.school,
          },
          {
            value: 'PRESCHOOL',
            label: this.$lang.app.preschool,
          }
        ],
        types: [
          {
            value: 'DOC',
            label: this.$lang.app.document,
          },
          {
            value: 'URL',
            label: this.$lang.app.link,
          }
        ]
      },
      file: null,
      helpItem: null,
      schema: yup.object({
        organizationType: yup.mixed().required(),
        type: yup.mixed().required(),
        titleRu: yup.string().required(),
        titleKg: yup.string().required(),
        url: yup.mixed().when('type', {
          is: 'URL',
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
      }),
    }
  },
  mounted() {
    this.fetchResource()
  },
  methods: {
    fetchResource() {
      this.$axios.get(`/manual/${this.$route.params.id}`).then(({data}) => {
        this.helpItem = {
          organizationType: data.organizationType,
          type: data.type,
          titleRu: data.titleRu,
          titleKg: data.titleKg,
          url: data.url,
        }
      })
    },
    submit(values, actions) {

      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('manualDto', JSON.stringify(this.helpItem))

      this.$axios.post( `/manual/edit/${this.$route.params.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            this.$snotify.success(this.$lang.app.resource_updated_successfully);
            this.$router.push('/admin/help')
          })
          .catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data);
              this.$snotify.success(error.response.message);
            }
          });
    },
  },
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>